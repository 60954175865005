@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;

.offerings-grid {

	@include mixins.grid(400px);

	.offering {
		border: solid 1px var(--wp--preset--color--bnpp-black);
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		position: relative;

		@media screen and (max-width: bp.$breakpoint-small) {
			flex-direction: column;
			flex-wrap: nowrap;
		}

		a {
			color: var(--wp--preset--color--white);
			text-decoration: none;
		}

		.offering-content {
			flex: 1 1 200px;
			padding: var(--wp--preset--spacing--60);
		}

		.icon {
			flex: 0 1 200px;
			position: relative;
			height: 100%;
    		display: flex;

			@media screen and (max-width: bp.$breakpoint-small) {

				position: static;
				
				svg {
					margin: 0 auto;
					max-width: 200px;
					width: 100%;
				}

			}

			&::after {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				width: 1px;
				background: currentColor;

				@media screen and (max-width: bp.$breakpoint-small) {
					top: 50%;
					right: auto;
					bottom: 0;
					left: 0;
					height: 1px;
					width: 100%;
				}
			}
		}
		
	}

	&.dark {


		.offering {
			border: solid 1px var(--wp--preset--color--white);
		}

	}

}